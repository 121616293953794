const data = [
    {
      id: 1,
      question: "Por que você fez um mestrado na área ambiental?",
      answer: "N/A."
    },
    {
      id: 2,
      question: "Por que você começou a dar aula?",
      answer: "N/A."
    },
    {
      id: 5,
      question: "Como eu contrato uma hospedagem e o registro um domínio?",
      answer: "Para hospedagem sites pessoais ou para o seu negócio em estágio incial, recomendo a Hostgator e Hostinger em função dos custo-benefício!"
    },
    {
      id: 4,
      question: "Quanto você cobra geralmente para desenvolver um website ou um app?",
      answer: "Envie um e-mail para contato@rodrigocoelho.me"
    }/*,
    {
      id: 3,
      question: "Qual a forma de pagamento?",
      answer: "Três vezes sem juros e desconto de 10% à vista no PIX!"
    },
    {
      id: 6,
      question: "E se o projeto não ficar bom?",
      answer: "Acontece nas melhores famílias, mas vamos dar um jeito!"
    }*/
    // inseri um comentário nas últimas duas pergunta pois preciso reformular
  ]

  export default data