import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineLinkedin} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'

const data = [
    {id: 1, link: 'https://instagram.com/rodrigocoelho.me', icon: <AiOutlineInstagram/>},
    {id: 2, link: 'https://linkedin.com/in/rodrigoscoelho', icon: <AiOutlineLinkedin/>},
    {id: 4, link: 'https://linktr.ee/rodrigoscoelho', icon: <AiFillGithub/>}
]

export default data